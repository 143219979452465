import React from "react";
import Grid from "@mui/material/Grid";
import { Fade } from "react-awesome-reveal";

import PortfolioItem from "../portfolio-item";

const Portfolio = () => {
  return (
    <div id="portfolio" className="layout">
      <Fade>
        <h2>/ portfolio</h2>
      </Fade>
      <Grid container spacing={1}>
        <p>
          For some reasons I can't show projects from my current company. There
          some old public project from the past:
        </p>
        <PortfolioItem
          delay={0}
          url="https://www.coffeeproject.ru/"
          name="Coffee Project"
          description="Coffee shop with blog, some services, accounts and bonus system. I've created all frontend and most of designs from scratch"
          techs="JavaScript, JQuery, HTML, SCSS, Gulp, Bootstrap"
        />
        <PortfolioItem
          delay={200}
          url="https://smartstory.world/"
          name="SmartStory"
          description="Personalized books with character creation process. Frontend & design by me"
          techs="JavaScript, HTML, SCSS, Gulp, Bootstrap"
        />
        <PortfolioItem
          delay={0}
          url="https://coffeehow.co/"
          name="CoffeeHow"
          description="Coffee blog, from scratch"
          techs="HTML, SCSS, Bootstrap, Wordpress"
        />
        <PortfolioItem
          delay={200}
          url="https://chernoskutov-design.ru"
          name="Egor Chernoskutov"
          description="Interior designer's site. From scratch."
          techs="HTML, SCSS, Bootstrap, Wordpress"
        />
        <p className="portfolio__also-links">
          Also:{" "}
          <a href="http://морешоп.рф" target="_blank" rel="noreferrer">
            Moreshop
          </a>
          ,{" "}
          <a href="https://suppsadvisor.com" target="_blank" rel="noreferrer">
            SuppsAdvisor
          </a>
          ,{" "}
          <a href="https://shoppy.dog/" target="_blank" rel="noreferrer">
            Shoppy.Dog
          </a>
          ,{" "}
          <a
            href="https://whitestardonuts.ru/"
            target="_blank"
            rel="noreferrer"
          >
            White Star Donuts
          </a>{" "}
          and others.
        </p>
      </Grid>
    </div>
  );
};

export default Portfolio;
