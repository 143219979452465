import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Intro from "../intro";
import About from "../about";
import Experience from "../experience";
import Portfolio from "../portfolio";
import Footer from "../footer";
import Menu from "../menu";

import "./index.scss";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3e5795",
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={11}>
            <Intro />
            <About />
            <Experience />
            <Portfolio />
          </Grid>
          <Grid item md={1} display={{ xs: "none", sm: "block" }}>
            <Menu />
          </Grid>
        </Grid>
        <Footer />
      </Container>
    </ThemeProvider>
  );
};

export default App;
