import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import GitHubIcon from "@mui/icons-material/GitHub";
import InstagramIcon from "@mui/icons-material/Instagram";
import AnchorLink from "react-anchor-link-smooth-scroll";

import "./index.scss";

const Menu = () => {
  return (
    <div className="menu">
      <AnchorLink href="#intro" className="menu__item">
        /home
      </AnchorLink>
      <AnchorLink href="#about" className="menu__item">
        /about
      </AnchorLink>
      <AnchorLink href="#experience" className="menu__item">
        /experience
      </AnchorLink>
      <AnchorLink href="#portfolio" className="menu__item">
        /portfolio
      </AnchorLink>
      <div className="menu__socials">
        <a
          href="mailto:subzero05chore@icloud.com"
          className="menu__icon"
          target="_blank"
          rel="noreferrer"
        >
          <EmailIcon />
        </a>
        <a
          href="https://github.com/ingvr"
          className="menu__icon"
          target="_blank"
          rel="noreferrer"
        >
          <GitHubIcon />
        </a>
        <a
          href="https://www.instagram.com/ingvr.design/"
          className="menu__icon"
          target="_blank"
          rel="noreferrer"
        >
          <InstagramIcon />
        </a>
      </div>
    </div>
  );
};

export default Menu;
