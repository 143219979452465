import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Fade } from "react-awesome-reveal";

import "./index.scss";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pl: 5 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Experience = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div id="experience" className="layout">
      <Fade>
        <h2>/ experience</h2>
        <Box className="experience__box">
          <Tabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
            aria-label="Experience"
            sx={{
              borderRight: 1,
              borderColor: "divider",
            }}
            className="experience__tabs"
          >
            <Tab className="experience__tab" label="Kontur" {...a11yProps(0)} />
            <Tab
              className="experience__tab"
              label="Coffee Project"
              {...a11yProps(1)}
            />
            <Tab className="experience__tab" label="Nendo" {...a11yProps(2)} />
            <Tab
              className="experience__tab"
              label="Freelance"
              {...a11yProps(3)}
            />
          </Tabs>
          <TabPanel value={value} index={0} className="experience__tab-panel">
            <h3>
              Software Engineer @ <span className="is-blue">SKB Kontur</span>
            </h3>
            <p>NOVEMBER 2021 - NOW</p>
            <ul className="about__list about__list_padding">
              <Fade direction={"up"} cascade duration={300}>
                <li className="about__li">
                  <KeyboardArrowRightIcon
                    className="about__icon"
                    sx={{ mr: 1 }}
                  />
                  Developing responsive React applications from scratch,
                  improving and refinement of existing applications, and writing
                  tests.
                </li>
                <li className="about__li">
                  <KeyboardArrowRightIcon
                    className="about__icon"
                    sx={{ mr: 1 }}
                  />
                  Building high-traffic and high-users applications.
                </li>
                <li className="about__li">
                  <KeyboardArrowRightIcon
                    className="about__icon"
                    sx={{ mr: 1 }}
                  />
                  Collaborations with various middle and senior engineers
                  (frontend and backend), product managers, system and data
                  analysts, and QAs. Following best practices for development
                  life cycles, including coding standards, code reviews, source
                  control management, build processes, testing, and operations.
                </li>
              </Fade>
            </ul>
          </TabPanel>
          <TabPanel value={value} index={1} className="experience__tab-panel">
            <h3>
              Frontend developer @{" "}
              <span className="is-blue">Coffee Project</span>
            </h3>
            <p>AUGUST 2018 - MARCH 2020</p>
            <ul className="about__list about__list_padding">
              <Fade direction={"up"} cascade duration={300}>
                <li className="about__li">
                  <KeyboardArrowRightIcon
                    className="about__icon"
                    sx={{ mr: 1 }}
                  />
                  Developed designs and frontend from scratch using JavaScript,
                  JQuery, HTML, SCSS, and Gulp. Integrated with backend using
                  Rest API.
                </li>
                <li className="about__li">
                  <KeyboardArrowRightIcon
                    className="about__icon"
                    sx={{ mr: 1 }}
                  />
                  We did both simple sites and complex projects with filtering
                  functions, data loading via ajax/axios, various calculators
                  and customizers for customers.
                </li>
              </Fade>
            </ul>
          </TabPanel>
          <TabPanel value={value} index={2} className="experience__tab-panel">
            <h3>
              Developer and founder @{" "}
              <span className="is-blue">Nendo Ltd.</span>
            </h3>
            <p>AUGUST 2013 - NOVEMBER 2018</p>
            <ul className="about__list about__list_padding">
              <Fade direction={"up"} cascade duration={300}>
                <li className="about__li">
                  <KeyboardArrowRightIcon
                    className="about__icon"
                    sx={{ mr: 1 }}
                  />
                  We did business with a partner selling corporate websites,
                  online stores, blogs.
                </li>
                <li className="about__li">
                  <KeyboardArrowRightIcon
                    className="about__icon"
                    sx={{ mr: 1 }}
                  />
                  The partner was engaged in communication with clients, I was
                  developing, mainly in HTML, CSS, JavaScript, and PHP. At
                  different times we tried Ruby on Rails development. Attracted
                  as freelance typesetters, designers, copywriters, and
                  promotion specialists.
                </li>
                <li className="about__li">
                  <KeyboardArrowRightIcon
                    className="about__icon"
                    sx={{ mr: 1 }}
                  />
                  In November 2018, the company with the customer base was sold.
                </li>
              </Fade>
            </ul>
          </TabPanel>
          <TabPanel value={value} index={3} className="experience__tab-panel">
            <h3>Freelancer</h3>
            <p>Before 2013 and occasionally before 2021</p>
            <ul className="about__list about__list_padding">
              <Fade direction={"up"} cascade duration={300}>
                <li className="about__li">
                  <KeyboardArrowRightIcon
                    className="about__icon"
                    sx={{ mr: 1 }}
                  />
                  There are many different sites from simple to complex.
                </li>
                <li className="about__li">
                  <KeyboardArrowRightIcon
                    className="about__icon"
                    sx={{ mr: 1 }}
                  />
                  Use to work with several small web studios
                </li>
              </Fade>
            </ul>
          </TabPanel>
        </Box>
      </Fade>
    </div>
  );
};

export default Experience;
