import React from "react";
import { Fade } from "react-awesome-reveal";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkIcon from "@mui/icons-material/Link";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";

import "./index.scss";

const PortfolioItem = (props) => {
  const { delay, url, github, name, description, techs } = props;

  function UrlLink(props) {
    const { url, type } = props;

    function TypeIcon(props) {
      const { type } = props;
      if (type === "github") {
        return <GitHubIcon />;
      } else {
        return <LinkIcon />;
      }
    }

    if (url) {
      return (
        <IconButton href={url} target="_blank" rel="noreferrer">
          <TypeIcon type={type} />
        </IconButton>
      );
    } else {
      return null;
    }
  }

  function ButtonLink(props) {
    const { url, type } = props;

    if (url) {
      return (
        <Button size="small" href={url} target="_blank" rel="noreferrer">
          {type}
        </Button>
      );
    } else {
      return null;
    }
  }

  return (
    <>
      <Grid item sm={6} display={{ xs: "none", md: "block" }}>
        <Fade
          direction={"up"}
          duration={200}
          delay={delay}
          className="portfolio__container"
        >
          <Card variant="outlined" className="portfolio__card">
            <CardActions disableSpacing>
              <UrlLink url={url} type="link" />
              <UrlLink url={github} type="github" />
            </CardActions>
            <CardContent className="portfolio__content">
              <h4>{name}</h4>
              <p>{description}</p>
              <small>{techs}</small>
            </CardContent>
          </Card>
        </Fade>
      </Grid>
      <Grid item sm={12} display={{ xs: "block", md: "none" }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <p>{description}</p>
            <small>{techs}</small>
            <CardActions sx={{ mt: 2 }}>
              <ButtonLink url={url} type="link" />
              <ButtonLink url={github} type="github" />
            </CardActions>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </>
  );
};

export default PortfolioItem;
