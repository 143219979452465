import React from "react";
import Typography from "@mui/material/Typography";

const Footer = () => {
  return (
    <div className="mui--text-center">
      <Typography align="center" mt={2} mb={2}>
        <small>Created with ♡ by Igor Leontev</small>
      </Typography>
    </div>
  );
};

export default Footer;
