import React, { useState } from "react";
import Typist from "react-typist";
import "react-typist/dist/Typist.css";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import { Fade } from "react-awesome-reveal";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Dialog from "@mui/material/Dialog";
import EmailIcon from "@mui/icons-material/Email";
import TelegramIcon from "@mui/icons-material/Telegram";
import PhoneIcon from "@mui/icons-material/Phone";

import "./index.scss";
import image from "./batou.jpg";

function ContactsDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
    window.open(value, "_blank").focus();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <List sx={{ pt: 0 }}>
        <ListItem
          button
          onClick={() =>
            handleListItemClick("mailto:subzero05chore@icloud.com")
          }
        >
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText primary={"subzero05chore@icloud.com"} />
        </ListItem>
        <ListItem
          button
          onClick={() => handleListItemClick("https://t.me/ingvr")}
        >
          <ListItemIcon>
            <TelegramIcon />
          </ListItemIcon>
          <ListItemText primary={"Telegram: @ingvr"} />
        </ListItem>
        <ListItem
          button
          onClick={() => handleListItemClick("tel:+79122525108")}
        >
          <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>
          <ListItemText primary={"+7 (912) 25-25-108"} />
        </ListItem>
      </List>
    </Dialog>
  );
}

const Intro = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <div id="intro" className="layout intro">
      <Fade>
        <h1 className="intro__title">
          <img src={image} alt="Hey you!" className="intro__image" />
          <Typist cursor={{ show: true, blink: true }}>
            Hey! <span className="intro__span">Ingvr</span> here!
          </Typist>
        </h1>
        <h2 className="intro__subtitle">
          Sometimes I've created interesting web applications
        </h2>
        <p>
          I'm a software engineer and designer based in Ekaterinburg, Russia. I
          have a professional interest in frontend development, UI/UX, and
          everything in betweenn
        </p>
        <Button
          variant="contained"
          size="large"
          endIcon={<SendIcon />}
          sx={{ mt: 2 }}
          onClick={handleClickOpen}
        >
          Contact Me
        </Button>
        <ContactsDialog open={open} onClose={handleClose} />
      </Fade>
    </div>
  );
};

export default Intro;
